<template>
  <BaseLoadingBox :pending="pending">
    <div class="row">
      <div class="col-md-4 col-xs-12">
        <BaseSelect
          v-model="filters.selectedOrg"
          :options="organizationOptions"
          label="title"
          :allow-empty="true"
          placeholder="Выбрать организацию"
          class="mb-s"></BaseSelect>
      </div>
      <div class="col-md-4 col-xs-12">
        <BaseSelect
          v-model="filters.selectedProfile"
          :options="profilesOptions"
          label="title"
          :allow-empty="false"
          placeholder="Выбрать профиль"
          class="mb-s"></BaseSelect>
      </div>
      <div class="col-md-4 col-xs-12">
        <BaseSelect
          v-model="filters.stage"
          :options="stageOptions"
          label="title"
          :allow-empty="false"
          placeholder="Выбрать этап"
          class="mb-s"></BaseSelect>
      </div>
    </div>
    <div
      ref="wrapper"
      class="chart-wrapper">
      <div class="chart">
        <canvas
          ref="chart"
          width="960"
          height="480"></canvas>
      </div>
    </div>
    <div
      v-if="error"
      class="form-error mt-s">
      {{ error }}
    </div>
  </BaseLoadingBox>
</template>

<script>
import { request } from "@/services/api";
import {
  Chart,
  PointElement,
  BubbleController,
  LinearScale,
  Title,
  Tooltip,
} from "chart.js";
import { INDIVIDUAL_STAGE, TEAM_STAGE } from "@/constants";
Chart.register(PointElement, BubbleController, LinearScale, Title, Tooltip);
const STAGE_OPTIONS = [
  {
    id: TEAM_STAGE,
    title: "Командный этап",
  },
  {
    id: INDIVIDUAL_STAGE,
    title: "Индивидуальный этап",
  },
];

// const COLORS = [
//   "rgba(42, 92, 167, 0.5)",
//   "rgba(77, 114, 184, 0.5)",
//   "rgba(0, 40, 101, 0.5)",
//   "rgba(240, 92, 129, 0.5)",
//   "rgba(0, 168, 98, 0.5)",
//   "rgba(0, 86, 255, 0.5)",
// ];
export default {
  name: "BubbleChart",
  props: {
    profileId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      error: "",
      pending: false,
      size: 100,
      stats: [],
      chart: null,
      bubbleColor: "rgba(240, 92, 129, 0.5)",
      filters: {
        selectedOrg: null,
        selectedProfile: null,
        stage: STAGE_OPTIONS[1], // индивидуальный этап по дефолту
      },
    };
  },
  computed: {
    stageOptions() {
      return STAGE_OPTIONS;
    },
    organizations() {
      return this.$store.state.mentor.organizations;
    },
    profilesOptions() {
      return Object.values(this.$store.state.profile.profiles).map(
        (profile) => {
          return {
            id: profile.id,
            title: profile.title,
          };
        }
      );
    },
    students() {
      return Object.values(this.$store.state.mentor.students).reduce(
        (acc, student) => {
          if (student.user?.talent_id) {
            acc[student.user.talent_id] = student;
          }
          return acc;
        },
        {}
      );
    },
    /**
     * Максимальный балл по профилю
     * нужно брать из поля `profile_max_score`
     * но могут быть кейсы когда у юзера будет больше баллов
     * по этому нужно брать максимальное значение
     */
    maxProfilescore() {
      const { stats } = this;
      if (!stats.length) return 200;
      const maxUsersScore = stats.sort((a, b) => {
        return b.summary_score > a.summary_score ? 1 : -1;
      });
      // баллы юзера + оффсет для того чтобы бабл влез
      const userMax = maxUsersScore[0].summary_score;
      return Math.max(stats[0]?.profile_max_score, userMax);
    },
    dataSet() {
      const { stats } = this;
      if (!stats.length) return [];
      return stats.map((item) => {
        return {
          x: Math.ceil((100 * item.started_tasks) / item.profile_tasks_count),
          y: item.summary_score,
          r: Math.max(5, item.started_activities * 5),
          id: item.talent_id,
          started_activities: item.started_activities,
        };
      });
    },
    scales() {
      return {
        x: {
          display: true,
          title: {
            display: true,
            text: "Процент решенных заданий",
          },
          min: 0,
          max: 100,
        },
        y: {
          display: true,
          title: {
            display: true,
            text: "Сумма баллов за профиль",
          },
          min: 0,
          max: this.maxProfilescore,
        },
      };
    },
    chartConfig() {
      const fontFamily =
        '"Proxima Nova", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';
      return {
        type: "bubble",
        data: {
          datasets: [
            {
              label: "",
              data: [...this.dataSet],
              backgroundColor: this.bubbleColor,
            },
          ],
        },
        options: {
          layout: {
            padding: 0,
          },
          responsive: true,
          scales: { ...this.scales },
          plugins: {
            legend: {
              display: false,
            },
            title: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              padding: 10,
              backgroundColor: "rgba(0, 3, 29, 0.8)",
              bodySpacing: 4,
              bodyFont: {
                size: 13,
                family: fontFamily,
              },
              titleFont: {
                size: 14,
                family: fontFamily,
              },
              callbacks: {
                label: (item) => {
                  return [
                    `${this.scales.x.title.text}: ${item.raw.x}`,
                    `${this.scales.y.title.text}: ${item.raw.y}`,
                    `Число предметов с баллами больше 0: ${item.raw.started_activities}`,
                  ];
                },
                beforeLabel: (item) => {
                  const student = this.students[item.raw.id];
                  if (student) {
                    return `${student?.first_name} ${student?.last_name}:`;
                  }
                  return `id ${item.raw.id}:`;
                },
              },
            },
          },
        },
      };
    },
    organizationOptions() {
      const { organizations } = this;
      return organizations.map((org) => {
        return {
          title: org.full_name || org.short_name,
          id: org.id,
        };
      });
    },
  },
  watch: {
    stats: {
      handler() {
        this.updateChart();
      },
    },
    filters: {
      handler() {
        this.getStats();
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const profile = this.profilesOptions.find((n) => n.id === this.profileId);
    if (profile) {
      this.filters.selectedProfile = profile;
    }
  },
  mounted() {
    this.makeChart();
  },
  methods: {
    makeChart() {
      const ctx = this.$refs.chart;
      this.chart = new Chart(ctx, this.chartConfig);
    },
    updateChart() {
      if (this.chart) {
        this.chart.data.datasets = [
          {
            label: "",
            data: [...this.dataSet],
            backgroundColor: this.bubbleColor,
          },
        ];

        this.chart.options.scales = this.scales;
        this.chart.update();
      }
    },
    async getStats() {
      const { filters } = this;
      const profile = filters.selectedProfile?.id;
      if (!profile) return;
      let url = `/mentor/students/stat/${profile}`;
      const params = {
        size: this.size,
        page: 1,
      };
      if (filters.selectedOrg?.id) {
        params.organization = filters.selectedOrg.id;
      }
      if (filters.stage?.id) {
        params.stage = filters.stage.id;
      }
      this.pending = true;
      try {
        const { data } = await request({
          method: "GET",
          url,
          params,
        });
        this.stats = data.items;
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
  },
};
</script>

<style lang="less" scoped>
.chart-wrapper {
  background-color: #fff;
  border-radius: @radius-m;
  box-shadow: @shadow-default;
  position: relative;
  &::before {
    content: "";
    display: block;
    padding-top: percentage(480/960);
  }

  .chart {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    // canvas {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    // }
  }
}
</style>
