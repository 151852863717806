<template>
  <div>
    <BaseModalContent
      :name="name"
      :dynamic="true"
      @onClose="reset"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-s">Пригласить ученика</h2>
      <div v-if="organizations && organizations.length">
        <p class="color-secondary mb-m">
          В&nbsp;выпадающем списке выберите организацию, в&nbsp;которую
          вы&nbsp;хотите пригласить участника. Затем скопируйте
          ссылку-приглашение и&nbsp;отправьте ее&nbsp;ученику любым удобным
          способом.
        </p>
        <div class="form-row">
          <label class="form-label text-bold">Организация*</label>
          <BaseSelect
            v-if="organizations.length"
            v-model="selected"
            :options="organizations"
            :searchable="false"
            :show-no-results="false"
            :preselect-first="true"
            label="title"
            :allow-empty="false"
            placeholder="Выбрать организацию"></BaseSelect>
        </div>
        <div
          v-if="selected && selected.isSchool"
          class="school-alert mb-m">
          <p class="mb-s">
            Обратите внимание, данная организация является образовательной. В
            нее нужно приглашать только тех, кто действительно в ней обучается,
            потому что информация об этой образовательной организации будет
            автоматически добавлена в учетную запись участника.
          </p>
          <p>
            Если участник, которого вы&nbsp;хотите пригласить, обучается
            в&nbsp;другом месте, выберите организацию, которая не&nbsp;является
            образовательной или
            <a
              href="#"
              class="link link--pseudo"
              @click.prevent="openCreateOrgModal"
              >создайте новую организацию</a
            >.
          </p>
        </div>
      </div>
      <div
        v-if="profiles.length"
        class="form-row">
        <label class="form-label text-bold">Профиль</label>
        <BaseSelect
          v-model="selectedProfile"
          :options="profiles"
          :searchable="true"
          :show-no-results="false"
          label="title"
          :allow-empty="false"
          :settings="{ maxHeight: 200 }"
          placeholder="Выбрать профиль"></BaseSelect>
      </div>
      <div class="form-row mb-l">
        <label
          class="form-label text-bold"
          for="invite-link-modal"
          >Ссылка-приглашение</label
        >
        <BaseInput
          id="invite-link-modal"
          :value="url"
          :placeholder="selected ? '' : 'Сначала выберите организацию'"
          readonly />
      </div>

      <div
        v-if="error"
        class="form-error mb-s">
        {{ error }}
      </div>
      <div class="btn-row">
        <BaseButton @click="copyInviteLink"
          ><DoneText :done="isCopied">Копировать ссылку</DoneText></BaseButton
        >&emsp;
        <BaseButton
          theme="primary-border"
          @click="handleClose"
          >Закрыть</BaseButton
        >
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
import { copyToClipboard, delay } from "@/utils";
import DoneText from "@/components/DoneText";
import CreateOrganizationModal from "./CreateOrganizationModal";
import { MODAL_DYNAMIC_DEFAULTS } from "@/constants";
export default {
  name: "InviteUserModal",
  components: { DoneText },
  props: {
    profile: Number,
  },
  data() {
    return {
      name: "invite-user",
      selected: null,
      url: "",
      error: "",
      selectedProfile: null,
      isCopied: false,
    };
  },
  computed: {
    organizations() {
      return this.$store.getters["mentor/orgList"].map((item) => {
        return {
          title: item.full_name || item.short_name,
          id: item.id,
          isSchool: !!item.educational_category,
        };
      });
    },
    profiles() {
      return this.$store.getters["profile/profiles"].map((n) => {
        return {
          title: n.title,
          id: n.id,
        };
      });
    },
  },
  watch: {
    selected: {
      handler(val) {
        if (val && val.id) {
          this.getLink();
        }
      },
    },
    selectedProfile: {
      handler() {
        this.getLink();
      },
    },
  },
  created() {
    if (this.organizations.length === 1) {
      this.selected = this.organizations[0];
    }
    if (this.profile) {
      this.selectedProfile = this.profiles.find(
        (item) => item.id === this.profile
      );
    }
  },
  methods: {
    openCreateOrgModal() {
      this.$modal.show(CreateOrganizationModal, {}, MODAL_DYNAMIC_DEFAULTS);
    },
    reset() {
      this.selectedProfile = null;
    },
    handleClose() {
      this.reset();
      this.$emit("close");
    },
    async getLink() {
      this.pending = true;
      this.url = await this.$store.dispatch("mentor/getUserInviteLink", {
        organization_id: this.selected.id,
        profile_id: this.selectedProfile?.id,
      });
      this.pending = false;
    },
    async copyInviteLink() {
      this.error = "";
      if (!this.selected) {
        this.error = "Сначала укажите организацию";
        return;
      }
      copyToClipboard(this.url);
      this.isCopied = true;
      window.dataLayer?.push({
        event: "ntoProfileEvent",
        eventCategory: "nto_profile",
        eventAction: "mentor_created_invite_link_for_students",
        ...(this.selectedProfile && {
          userProfile: this.selectedProfile.title,
        }),
      });
      await delay(1500);
      this.isCopied = false;
      // this.reset();
    },
  },
};
</script>

<style lang="less" scoped>
.school-alert {
  border-left: 4px solid @warn-color;
  background-color: fade(@warn-color, 5%);
  padding: 20px 20px 20px 30px;
}
</style>
