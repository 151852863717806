<template>
  <div>
    <ProfilePanel
      :id="id"
      class="mb-xxl">
      <template #controls>
        <div class="profile__controls">
          <a
            href="#"
            class="profile__control link"
            @click.prevent="handleInvite">
            <BaseIcon
              glyph="close"
              width="17px"
              class="profile__control-icon plus" />Пригласить ученика в этот
            профиль
          </a>

          <a
            v-if="!isFavorite"
            href="#"
            class="profile__control link"
            @click.prevent="handleSelectProfile">
            <BaseIcon
              glyph="close"
              width="17px"
              class="profile__control-icon plus" />Добавить профиль в избранное
          </a>
          <a
            v-else
            href="#"
            class="profile__control link"
            @click.prevent="handleRefuseProfile">
            <BaseIcon
              glyph="close"
              width="19px"
              class="profile__control-icon" />Удалить профиль из избранного
          </a>
        </div>
      </template>
    </ProfilePanel>
    <p
      v-if="studentsPending"
      class="mb-l">
      <BaseSpinner
        brand
        class="students-spinner" />
      Получаю данные о&nbsp;прогрессе учеников...
    </p>
    <div v-else>
      <div v-if="students.length">
        <BubbleChart
          class="mb-l"
          :profile-id="id" />
        <div v-if="students.length > 1">
          <!-- Фильтры -->
          <div class="row mb-m">
            <div class="col-lg-7 col-md-8 col-sm-7 col-xs-12">
              <h4 class="text-size-h4 text-bold">
                Выберите одного или нескольких учеников, чтобы посмотреть
                их&nbsp;прогресс
              </h4>
            </div>
          </div>
          <!-- Фильтр по ученикам -->
          <div class="row cards-list mb-m">
            <div
              v-for="student in students"
              :key="student.id"
              class="col-sm-6 col-xs-12 cards-list__item">
              <div
                class="panel profile__student"
                tabindex="0"
                @click="handleSelectStudent(student.id)"
                @keydown.enter="handleSelectStudent(student.id)">
                <RegCheckbox
                  :checked="selectedStudents.includes(student.id)"
                  class="profile__student-check" />
                <div>
                  <div class="text-size-h4 text-bold">
                    {{ student.first_name }} {{ student.last_name }}
                  </div>
                  <div class="text-size-xs color-meta">
                    <span v-if="student.gradeText">
                      {{ student.gradeText }}&emsp;</span
                    >{{ student.education_title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Пузырьковая диаграма по профилю  -->

        <!-- Фильтр по предметам -->
        <div class="mb-l">
          <label class="text-bold">Предметы ученика:</label>
          <p class="text-size-s color-meta mb-s">
            Сократите количество отображаемых предметов в&nbsp;списке ниже, если
            это необходимо
          </p>
          <div class="row">
            <div class="col-md-4 col-sm-4 col-xs-12">
              <BaseSelect
                v-model="selectedActivities"
                :options="activitiesOptions"
                label="title"
                track-by="id"
                placeholder="Предметы"
                :multiple="true"
                :close-on-select="false">
                <template #selection="{ values, isOpen }">
                  <div v-if="values.length === 1 && !isOpen">
                    {{ values[0].title }}
                  </div>
                  <div v-else-if="values.length && !isOpen">
                    Выбрано {{ values.length }}
                  </div>
                </template>

                <template #noResult>Такого предмета нет в списке</template>
              </BaseSelect>

              <div class="select-reset text-size-s">
                <a
                  v-if="selectedActivities.length"
                  href="#"
                  class="link link--pseudo"
                  @click.prevent="selectedActivities = []"
                  >Очистить поле</a
                >
                &nbsp;
              </div>
            </div>
          </div>
        </div>

        <!-- Прогрессы -->
        <div
          v-for="student in filteredStudents"
          :key="student.id"
          class="mb-l">
          <div class="text-size-h4 text-bold color-secondary mb-s">
            Ученик&nbsp;&mdash;&nbsp;
            <router-link
              class="link"
              :to="{
                name: 'mentor-student',
                params: {
                  id: student.id,
                },
              }">
              {{ student.first_name }} {{ student.last_name }}
            </router-link>
          </div>

          <ActivityCard
            v-for="item in filteredActivities"
            :key="item.id"
            class="mb-xs"
            :activity="item"
            :student-id="Number(student.id)"
            :is-mentor="true"></ActivityCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePanel from "@/components/profile/ProfilePanel";
import InviteUserModal from "@/components/mentor/modals/InviteUserModal";
import RegCheckbox from "@/components/registration/RegCheckbox";
import ActivityCard from "@/components/profile/ActivityCard";
import { STUDENT_ALIAS, INDIVIDUAL_STAGE } from "@/constants";
import { request } from "@/services/api";
import BubbleChart from "@/components/mentor/BubbleChart";

export default {
  name: "MentorProfile",
  components: {
    ProfilePanel,
    RegCheckbox,
    ActivityCard,
    BubbleChart,
  },

  metaInfo() {
    return {
      title: `${this.profile?.title} - Страница профиля`,
      titleTemplate: `%s - личный кабинет наставника Национальной технологической олимпиады`,
    };
  },
  data() {
    return {
      students: [],
      selectedStudents: [],
      selectedActivities: [],
      studentsPending: false,
    };
  },
  computed: {
    id() {
      return +this.$route.params.id;
    },

    selectedProfiles() {
      return this.$store.state.mentor.profiles;
    },
    isFavorite() {
      return !!this.selectedProfiles[this.id];
    },

    profile() {
      return this.$store.state.profile.profiles[this.id];
    },

    activitiesOptions() {
      if (!this.profile) return [];
      return this.profile.activities;
    },

    filteredStudents() {
      if (this.selectedStudents.length) {
        return this.students.filter(({ id }) =>
          this.selectedStudents.includes(id)
        );
      } else {
        return this.students;
      }
    },

    individualStageActivity() {
      if (!this.profile?.activities) return [];
      return this.profile.activities?.filter((activity) => {
        return activity.stage === INDIVIDUAL_STAGE;
      });
    },

    filteredActivities() {
      if (this.selectedActivities.length) {
        const activitiesIds = this.selectedActivities.map(({ id }) => id);
        return this.individualStageActivity.filter(({ id }) =>
          activitiesIds.includes(id)
        );
      } else {
        return this.individualStageActivity;
      }
    },
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.init();
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.studentsPending = true;
      try {
        const { data } = await request({
          url: `/mentor/students`,
          params: {
            profile: this.id,
          },
        });

        const tracks = this.$store.state.tracks;

        this.students = data.items.map((student) => {
          const gradeText =
            tracks[student.track_id]?.alias !== STUDENT_ALIAS
              ? `${student.grade} класс`
              : "";
          return {
            ...student,
            gradeText,
          };
        });
      } catch (error) {
        this.showErrorModal({
          content: "Не удалось получить учеников по этому профилю",
          message: error.message,
        });
      }
      this.studentsPending = false;
    },
    handleInvite() {
      this.$modal.show(
        InviteUserModal,
        { profile: this.id },
        { adaptive: true, scrollable: true, minHeight: 400, height: "auto" }
      );
    },

    async handleRefuseProfile() {
      const { isFavorite, id } = this;
      // если профиль выбран
      if (isFavorite) {
        try {
          await this.$store.dispatch("mentor/rejectProfile", id);
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error?.response?.status,
          });
        }
      }
    },
    async handleSelectProfile() {
      const { isFavorite, id } = this;
      // если профиля нет в выбранных
      if (!isFavorite) {
        try {
          await this.$store.dispatch("mentor/selectProfile", id);
        } catch (error) {
          this.showErrorModal({
            message: error.message,
            status: error.status,
          });
        }
      }
    },

    handleSelectStudent(id) {
      const idx = this.selectedStudents.indexOf(id);
      if (idx > -1) {
        this.selectedStudents.splice(idx, 1);
      } else {
        this.selectedStudents.push(id);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.students-spinner {
  vertical-align: middle;
  margin-right: 0.5em;
}
.profile {
  &__controls {
    margin-left: -10px;
    margin-right: -10px;
  }
  &__control {
    display: inline-block;
    margin: 0 10px;

    &-icon {
      margin-right: 5px;

      &.plus {
        transform: rotate(45deg);
      }
    }

    .media-max-md({
      margin-bottom: 15px;
    });
  }

  &__student {
    height: 100%;
    display: flex;
    cursor: pointer;

    &-check {
      flex-basis: 24px;
      margin-right: 10px;
    }
  }
}
.select-reset {
  padding-top: 0.2em;
  text-align: right;
}
</style>
