<template>
  <section
    v-if="profile"
    class="p-panel">
    <div>
      <div class="p-panel__icon">
        <BaseImage
          v-if="profile.icon"
          :src="profile.icon" />
        <BaseImage
          v-else
          src="@/assets/images/profile_placeholder.svg" />
      </div>
      <div class="p-panel__controls"><slot name="controls"></slot></div>

      <h1 class="text-size-h2 text-bold mb-m p-panel__title">
        {{ profile.title }}
        <AppTag
          v-if="stage"
          theme="border"
          class="text-size-s"
          >{{ stage }}</AppTag
        >
      </h1>
      <ul
        v-if="profile.routes && profile.routes.length"
        class="p-panel__tags mb-m">
        <li
          v-for="tag in profile.routes"
          :key="tag.id"
          class="p-panel__tags-item">
          <AppTag>{{ tag.title }}</AppTag>
        </li>
      </ul>

      <div
        v-if="profile.short_description"
        class="row mb-m">
        <div class="col-md-9 col-xs-12">
          {{ profile.short_description }}
        </div>
      </div>

      <ul
        v-if="chats"
        class="p-panel__chats mb-m">
        <li
          v-for="chat in chats"
          :key="chat.id"
          class="p-panel__chats-item">
          <a
            class="link"
            :href="chat.url"
            target="_blank"
            rel="noopener noreferrer"
            >{{ chat.title }}</a
          >
        </li>
      </ul>
      <slot></slot>
    </div>
  </section>
</template>

<script>
import AppTag from "@/components/AppTag";
export default {
  name: "ProfilePanel",
  components: {
    AppTag,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    stage: {
      type: String,
    },
  },
  computed: {
    profile() {
      return this.$store.state.profile.profiles[this.id];
    },
    chats() {
      return this.profile?.chats?.filter((n) => !n.teamlink && !n.team_secret);
    },
  },
};
</script>

<style lang="less" scoped>
@p-panel-padding: 24px;
.p-panel {
  padding: @p-panel-padding;
  background-color: #fff;
  margin-top: 30px;
  box-shadow: @shadow-default;
  border-radius: @radius-xl;
  position: relative;

  &__icon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: @tropical-blue;
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 15px;
    position: relative;
    border: 4px solid #fff;
    box-shadow: 0px 10px 10px @tropical-blue;
    overflow: hidden;
    margin-top: -40px;
    margin-bottom: 20px;
  }

  &__title {
    .a-tag {
      vertical-align: middle;
    }
  }

  &__tags {
    list-style-type: none;
    font-size: @font-size-s;

    &-item {
      display: inline-block;
      margin-right: 0.95em;
      margin-bottom: 0.95em;
    }
  }

  &__chats {
    list-style-type: none;
    // .fz(15);
    &-item {
      display: inline-block;
      vertical-align: middle;
      margin-right: 1.4em;
      color: @link-color;
      .rem(margin-bottom, @margin-xs);
      &::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 0.4em;
        height: 0.4em;
        margin-right: 0.4em;
        background-color: @link-color;
        transform: rotateZ(45deg);
        opacity: 0.3;
      }
    }
  }

  &__controls {
    position: absolute;
    top: @p-panel-padding;
    right: @p-panel-padding;

    .media-max-md({
      position: relative;
      top: initial;
      right: initial;
    });
  }
}
</style>
