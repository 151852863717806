<template>
  <div>
    <BaseModalContent
      name="createOrgModal"
      :dynamic="true"
      @onClose="reset"
      @close="$emit('close')">
      <h2 class="text-bold text-size-h2 mb-m">Создать организацию</h2>
      <ValidationObserver
        ref="form"
        tag="form"
        @submit.prevent="handleSubmit">
        <div class="form-row">
          <label class="form-label">Адрес организации*</label>
          <ValidationProvider
            v-slot="{ errors }"
            mode="aggressive"
            rules="required">
            <AddressAutocomplete
              v-model="address"
              placeholder="Укажите город"
              :errors="errors"
              :search-options="{
                from_bound: { value: 'city' },
                locations: [{ country: '*' }],
                to_bound: { value: 'settlement' },
              }" />
          </ValidationProvider>
        </div>
        <div class="form-row">
          <label
            for="org_name"
            class="form-label"
            >Название организации*</label
          >

          <ValidationProvider
            v-slot="{ errors }"
            rules="required">
            <BaseInput
              id="org_name"
              v-model="full_name"
              :errors="errors"
              placeholder="Например: Домашняя подготовка" />
          </ValidationProvider>
        </div>
        <div class="mt-l">
          <div
            v-if="error"
            class="form-error mb-m mt-m">
            Не удалось создать организацию.
            <p>{{ error }}</p>
          </div>

          <BaseButton
            :loading="pending"
            class="mr-m mb-m"
            type="submit"
            >Создать организацию</BaseButton
          >
          <BaseButton
            type="button"
            theme="primary-border"
            @click.prevent="$emit('close')"
            >Отмена</BaseButton
          >
        </div>
      </ValidationObserver>
    </BaseModalContent>
  </div>
</template>

<script>
import AddressAutocomplete from "@/components/autocomplete/AddressAutocomplete.vue";
import { talentRequest } from "@/services/api";
export default {
  name: "CreateOrganizationModal",
  components: {
    AddressAutocomplete,
  },
  data() {
    return {
      pending: false,
      full_name: "",
      address: "",
      error: "",
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
  },
  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.pending = true;
      try {
        await talentRequest({
          method: "POST",
          url: `/api/users/${this.user.talent_id}/organizations/`,
          data: {
            full_name: this.full_name,
            short_name: this.short_name,
            address: this.address,
          },
        });
        window.dataLayer?.push({
          event: "ntoProfileEvent",
          eventCategory: "nto_profile",
          eventAction: "mentor_created_new_org",
        });
        try {
          await this.$store.dispatch("mentor/getMyOrganizations", true);
        } catch (error) {
          console.log("error", error);
        }
        this.$emit("close");
      } catch (error) {
        this.error = error.message;
      }
      this.pending = false;
    },
    reset() {
      this.pending = false;
      this.error = "";
      this.full_name = "";
      this.address = "";
      this.$refs.form.reset();
    },
  },
};
</script>

<style></style>
